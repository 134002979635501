/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : MediaQuery 1024 - Tablet
    Author     : Rizki Nida Chaerulsyah - akuiki.net
==============================================================================================*/

@media only screen and (min-width:0px) and (max-width: 1370px) {
    .floatingmenu input[type='text'],
    select,
    input[type='submit'] {
        font-size: 16px;
    }
    .inner-content.product-detail .grid-view .box {
        min-height: 250px;
    }
}

@media only screen and (min-width:961px) and (max-width: 1330px) {
    .accordion-wp .floatingmenu {
        width: 81%;
        height: 112px;
    }
    .floatingmenu .row .col.small {
        width: 80px;
    }
    .floatingmenu .row .col.sub {
        width: 16%;
    }
    .floatingmenu .row .col {
        width: 22%;
    }
}

@media only screen and (min-width:0px) and (max-width: 1330px) {
    /* structure
    ----------------------------------------------------------------------------------------------*/
    header .left {
        margin: 13px 40px 0 0;
    }
    header .left a.phone {
        padding-left: 30px;
    }
    header .left a {
        padding: 1px 0 1px 30px;
        font-size: 14px;
    }
    header .left a {
        padding: 1px 0 1px 22px;
    }
    header .left:after {
        right: -25px;
    }
    .menu-content .left {
        width: 35%;
    }
    .menu-content .right {
        width: 65%;
    }
    .menu-content .left .search input[type=text] {
        width: 90%;
        height: 40px;
    }
    .menu-content .logo {
        top: 15px;
        right: 15px;
        width: 230px;
    }
    .menu-content .right h5 {
        font-size: 18px;
    }
    .menu-content .grid-view .box {
        width: 50%;
    }
    .menu-content .grid-view .box:last-child {
        display: none;
    }
    .menu-content .right .box-news a {
        font-size: 24px;
        line-height: 28px;
    }
    .menu-content .right .box-news a span {
        font-size: 14px;
    }
    .menu-content .right .wrapper {
        margin: 95px 6.5% 0;
    }
    .filter-tahun label {
        font-size: 16px;
        margin: 0 10px 0 0;
    }
    .filter-tahun input[type=text] {
        margin: 0 10px 0 0;
    }
    .floatingmenu .form {
        margin-top: 15px;
    }
    .floatingmenu .row input[type='submit'] {
        font-size: 12px;
        margin-top: 21px;
        min-width: 150px;
        margin-top: 21px;
    }
    .form-message input[type='text'],
    input[type='file'],
    select {
        height: 35px;
        font-size: 12px;
    }
    .floatingmenu input[type='text'],
    select,
    input[type='submit'] {
        height: 35px;
        font-size: 12px;
    }
    .inner-content .qoutes:before {
        background: transparent;
    }
    .inner-content .qoutes:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background: #e2211a;
        bottom: -50px;
        height: 2px;
        width: 100px;
    }
    .inner-content .qoutes {
        padding-left: 0;
        margin-bottom: 80px;
    }
    .inner-content .list-direksi .box .desc p {
        padding: 40px 50px 0;
    }
    .inner-content .list-direksi .box .desc a {
        margin: 0 50px 0;
        padding: 50px 30px 50px 0;
    }
    .btn-readmore-red {
        line-height: 42px;
    }
    .floatingmenu .row label {
        font-size: 11px;
    }
    .floatingmenu .row .col {
        position: relative;
        z-index: 4;
    }
    .grid-view .box--grid .box--column.nasabah .text .link a,
    .grid-view .box--grid .box--column.nasabah .text .link span {
        font-size: 12px;
    }
    .ls-acc-wp .btn-acc {
        margin-left: 20px;
    }
}

@media only screen and (min-width:980px) and (max-width: 1330px) {
    /* middle
    ----------------------------------------------------------------------------------------------*/
    .solutin {
        bottom: 40vh;
    }
    .grid-view .box.slider-wrap .text h2 {
        font-size: 44px;
    }
    .grid-view .box.slider-wrap .text p {
        margin-bottom: 20px;
    }
    .grid-view .box .text {
        padding: 13px;
    }
    .grid-view .box .text h4 {
        font-size: 17px;
        margin: 0 0 4px;
    }
    .grid-view .box .text h6 {
        font-size: 14px;
        line-height: 16px;
    }
    .grid-view .box .text p {
        margin: 0 0 7px;
        font-size: 12px;
        line-height: 14px;
        height: 27px;
        overflow: hidden
    }
    .grid-view .box .text span {
        font-size: 10px;
    }
    .grid-view .box--grid .text-money h5 {
        font-size: 18px;
    }
    .grid-view .box--grid .text-money span {
        font-size: 12px;
    }
    .grid-view .box--grid .box--column.fund .text-money span {
        line-height: 15px;
        font-size: 11px;
    }
    .grid-view .box--grid .box--column.fund .text-money {
        padding: 0 15px 20px;
        height: auto;
    }
    .grid-view .box--grid .box--column.fund .text-money h2 {
        margin: 7% 0 15%;
    }
    .grid-view .box--grid .box--column.fund .text-money h2 span {
        margin-bottom: 0;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a {
        height: 30px;
        width: 17.5px;
        top: 50%;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a.bx-next {
        right: 21%;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a.bx-prev {
        left: 21%;
    }
    .grid-view .box--grid .box--column.fund.testi h3 {
        margin: 15px 0 20px 0;
    }
    .grid-view .box--grid .box--column.fund.testi .img-testi {
        display: none;
    }
    .grid-view .box--grid .box--column.fund.testi p {
        font-size: 12px;
        line-height: 16px;
        height: 51px;
    }
    .grid-view .box--grid .box--column.fund.testi .bx-wrapper .bx-controls-direction {
        bottom: 35px;
    }
    .grid-view .box .text.perencanaan {
        padding-bottom: 10px;
    }
    .grid-view .box .text.perencanaan h6 {
        font-size: 16px;
        margin: 0 0 2px 0;
        line-height: 16px;
    }
    .grid-view .box .text.perencanaan a {
        margin: 0;
    }
    .grid-view .box--grid .text-money {
        padding: 20px 15px;
    }
    .grid-view .box--grid .box--column.fund h5 {
        margin-top: 20px;
        padding: 0 30px;
    }
    .grid-view .box--grid .box--column.customer .text img {
        width: 33px;
        margin: 0 0 10px 0;
    }
    .grid-view .box--grid .box--column.customer .text h2 {
        font-size: 24px;
    }
    .grid-view .box--grid .box--column.callus .text h5 {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .grid-view .box--grid .box--column.callus .text h6 {
        font-size: 22px;
        margin: 0 0 25px 0;
    }
    .grid-view .box--grid .box--column.callus .text img {
        margin: 0px;
        width: 60px;
    }
    .grid-view .box .label-ico,
    .grid-view .box--grid .label-ico {
        right: 10px;
        top: 10px;
    }
    .grid-view .box .label-ico img,
    .grid-view .box--grid .label-ico img {
        width: 24px;
    }
    .grid-view .box--column.news .text {
        bottom: 30px;
    }
    .grid-view .box--column.news .text h3 {
        font-size: 20px;
    }
    .grid-view .box .social-embed h5,
    .grid-view .box--grid .social-embed h5 {
        margin-bottom: 10px;
    }
    .grid-view .box .social-embed h5 span,
    .grid-view .box--grid .social-embed h5 span {
        display: none;
    }
    .grid-view .box .social-embed h5 b,
    .grid-view .box--grid .social-embed h5 b {
        font-size: 20px;
    }
    .grid-view .box .social-embed .embed-area,
    .grid-view .box--grid .social-embed .embed-area {
        height: 160px;
    }
    .grid-view .box--grid .box--column.customer .text span,
    .grid-view .box--grid .box--column.customer .text a {
        display: none;
    }
    .content .banner {
        height: 365px;
    }
    .content .content-wrapper {
        width: 845px;
    }
    .content .title-page .ico {
        width: 60px;
        height: 60px;
    }
    .content .title-page h6 {
        line-height: 65px;
    }
    .content-container .content-wrapper .inner-content {
        padding-right: 45px;
    }
    .inner-content {
        width: 635px;
        padding-left: 85px;
    }
    .inner-content h2 {
        font-size: 45px;
        line-height: 50px;
    }
    .inner-content h4 {
        font-size: 24px;
        line-height: 28px;
    }
    .inner-content h5 {
        margin: 0 0 20px 0;
        font-size: 22px;
    }
    .inner-content h6 {
        font-size: 18px;
        margin: 0 0 10px;
    }
    .inner-content blockquote,
    .inner-content p.quote {
        font-size: 24px;
        line-height: 28px;
    }
    .wrap-popup .box-popup.about p,
    .wrap-popup .box-popup.about ul li,
    .inner-content table,
    .inner-content ul li,
    .inner-content ol li,
    .inner-content p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
    }
    .inner-content .image.offset {
        margin: 40px 0 40px -90px;
    }
    .inner-content .list-direksi .box-title,
    .inner-content .list-direksi .box {
        float: left;
        width: 316px;
        min-height: 385px
    }
    .inner-content .list-direksi .box .image {
        height: 385px;
    }
    .inner-content .list-direksi .box-title h2 {
        font-size: 30px;
        line-height: 36px;
        margin: 70px 40px 0;
    }
    .inner-content .list-direksi .box .box-blue {
        height: 385px;
    }
    .inner-content .list-direksi .box .text {
        top: 275px;
        left: 30px;
    }
    .inner-content .list-direksi .box .text h3 {
        font-size: 28px;
        line-height: 25px;
    }
    .inner-content .list-direksi .box .text i {
        font-size: 16px;
    }
    .inner-content .wrap-tab .nav-tab {
        margin: 0px 0 35px;
    }
    .inner-content .wrap-tab .nav-tab a {
        height: 50px;
        line-height: 53px;
        width: auto;
        padding: 0 15px;
        font-size: 14px
    }
    .list-rekanan .box {
        padding: 15px 0;
    }
    .list-rekanan .box h3 {
        font-size: 20px;
        height: 35px;
        line-height: 35px;
        padding: 0 0 0 20px;
    }
    .list-box .box {
        width: 208px;
        height: 208px;
    }
    .list-box .box .text {
        padding: 55px 20px 0;
        position: relative;
    }
    .list-box .box p {
        font-size: 12px;
    }
    .list-box .box .label-ico {
        width: 25px;
        height: 25px;
        top: 10px;
        right: 10px;
        background-size: 100% auto !important;
    }
    .list-box .box h6 {
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        font-weight: normal;
    }
    .list-box .box a {
        left: 20px;
        bottom: 20px;
        font-size: 12px;
    }
    .content .title-page .nav a {
        width: 60px;
        height: 60px;
        background-size: 120px auto !important;
    }
    .content .title-page .nav {
        width: 60px;
        top: 60px;
    }
    .inner-content ul.col-3 li {
        margin: 0 0 7px;
        width: 295px;
    }
    .inner-content .grid-view .box {
        width: 210px;
    }
    .inner-content .grid-view .box.brosur .text p {
        margin: 0 0 8px;
        font-size: 12px;
        height: auto;
        overflow: hidden;
    }
    .inner-content .grid-view .box.brosur h3 {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .inner-content .grid-view .box .text {
        padding: 20px;
    }
    .inner-content .grid-view .box--grid .box--column.callus h3 {
        font-size: 20px;
    }
    .inner-content .grid-view .box--grid .box--column.callus img {
        margin-bottom: -10px;
        height: auto;
    }
    .inner-content .grid-view .box--grid .box--column.callus .text:after {
        width: 75px;
        height: 64px;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a {
        width: 18px;
    }
    .grid-view.news .box.testi h3 {
        margin: 15px 0 15px 0;
    }
    .grid-view .box--grid .testi .bx-viewport {
        max-height: 150px;
        min-height: 150px;
    }
    .grid-view.news .box--column.kontak-media h3 {
        font-size: 24px;
        line-height: 21px;
    }
    .grid-view.news .box--column.kontak-media p {
        font-size: 12px;
        line-height: 13px;
    }
    .grid-view.news .box--column.kontak-media h6 {
        font-size: 16px;
    }
    .grid-view.news .box--column.kontak-media a.mail {
        font-size: 11px;
    }
    .grid-view.news .box--column.kontak-media i {
        font-size: 10px;
    }
    .grid-view.news .box--column.kontak-media a {
        right: 15px;
        bottom: 15px;
    }
    .bar-filter .search input,
    .bar-filter .dropdown .cover-combo,
    .bar-filter .dropdown select,
    .bar-filter .dropdown .inner-combo,
    .bar-filter {
        height: 60px;
        line-height: 60px;
    }
    .bar-filter .search input,
    .bar-filter .dropdown .inner-combo {
        font-size: 14px;
    }
    .inner-content .grid-view.career .box .text {
        padding: 15px;
    }
    .inner-content .grid-view.career .text p {
        font-size: 12px;
        margin: 5px 0 10px 0;
    }
    .inner-content .grid-view.career .text h3 {
        font-size: 20px
    }
    .inner-content .grid-view.career .text img {
        height: 45px;
        margin: 0 auto 10px;
    }
    .inner-content .grid-view.career .text a {
        margin-right: 0;
        overflow: hidden;
        line-height: 15px;
        padding: 0 20px 0 0;
        display: inline-block;
        width: 110px;
    }
    .list-box.career_list .box .kode_career {
        font-size: 12px;
    }
    .list-box .box .text {
        padding: 45px 15px 0;
    }
    .list-box.career_list .box h6 {
        line-height: 20px;
        font-size: 18px;
        margin: 0 0 0px;
    }
    .inner-content.career .top_src select {
        width: 170px;
        height: 30px;
    }
    .inner-content.career .top_src .cover-combo {
        height: 35px;
    }
    .inner-content.career .top_src .cover-combo .inner-combo {
        font-size: 13px;
        line-height: 17px;
        padding: 10px 10px;
    }
    .inner-content.career .top_src .col:first-child {
        margin-right: 0px;
    }
    .inner-content.career .top_src .cover-combo .inner-combo {
        font-size: 13px;
        line-height: 17px;
    }
    .inner-content.career .top_src label {
        font-size: 16px;
    }
    .inner-content.career .top_src select {
        width: 150px;
    }
    .inner-content.career .top_src label {
        font-size: 14px;
    }
    .inner-content.career .top_src .col {
        margin-right: 10px;
    }
    .filter-tahun input[type=submit],
    .inner-content.career .top_src input[type="submit"] {
        color: #fff;
        height: 37px;
        font-size: 14px;
        font-weight: 700;
        background: #e2211a;
        border: none;
        text-transform: uppercase;
        width: 149px;
        cursor: pointer;
    }
    .inner-content.career.apply h4 {
        margin-top: -10px;
    }
    .form_apply .row .col.full input[type="text"] {
        width: 593px;
    }
    .input_file,
    .form_apply .row .col input[type="text"] {
        width: 435px;
        padding: 10px 20px;
        font-size: 16px;
        width: 265px;
        font-size: 14px;
        background-size: 16px auto !important;
    }
    .form_apply .row .col label {
        display: block;
        font-size: 16px;
    }
    .inner-content .form_contact .row:first-child select {
        width: 85px;
        height: 38px;
    }
    .inner-content .form_contact .row .cover-combo .inner-combo {
        font-size: 14px;
        padding: 12px;
    }
    .inner-content .form_contact .row .cover-combo {
        height: 38px;
    }
    .inner-content .form_contact .row:first-child input[type="text"] {
        width: 502px;
    }
    .inner-content .form_contact .row input[type="text"],
    .inner-content .form_contact .row textarea {
        font-size: 14px;
        padding: 11px;
    }
    .inner-content .form_contact .row input[type="text"] {
        width: 282px;
    }
    .inner-content .form_contact .row:nth-child(2) input[type="text"] {
        margin-right: 10px;
    }
    .inner-content .form_contact .row .cover-combo {
        margin-right: 10px;
    }
    .inner-content .form_contact .row label {
        font-size: 16px;
        margin-bottom: 9px;
    }
    .inner-content .form_contact .row:nth-child(3) input[type="text"] {
        width: 388px;
    }
    .inner-content .form_contact .row .div_check span {
        font-size: 14px;
    }
    .inner-content .form_contact .row select {
        height: 38px;
    }
    .inner-content .form_contact .row:nth-child(4) select {
        width: 628px;
    }
    .inner-content .form_contact .row textarea {
        width: 605px;
        height: 120px;
    }
    .grid-view .box--grid .box--column.callus .text:after {
        opacity: 0;
        content: "";
        display: block;
        width: 75px;
        height: 69px;
        position: absolute;
        background: url(../images/material/telephonee.gif) no-repeat center;
        background-size: 108% auto;
        border: none;
        top: 40px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .inner-content .box-testi .text {
        width: 480px;
    }
}

@media only screen and (min-width:0px) and (max-width: 900px) {
    .inner-content .qoutes {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 60px;
    }
    .inner-content .qoutes:after {
        bottom: -30px;
    }
    .box-slideristimewa .list-slider {
        padding: 20px 15px;
        float: none;
    }
    .box-slideristimewa .list-slider p {
        font-size: 13px;
    }
    .btn-readmore {
        font-size: 16px;
    }
    .form-message h3 {
        font-size: 24px;
        line-height: 26px;
    }
    .box-slidermanfaat .list-slider {
        float: none;
        padding: 20px 15px;
    }
}

@media only screen and (min-width: 980px)and (max-width: 1024px) {
    .grid-view .box.slider-wrap .text,
    .grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager {
        bottom: 5px;
    }
}

@media only screen and (min-width: 981px) {
    .content .content-wrapper {
        margin: -70px auto 0 auto;
        box-sizing: content-box;
    }
    .grid-view .box--grid .box--column.col-span-2 {
        grid-column: span 2/span 2;
    }
    .grid-view .box--grid .box--column .box--grid-wrapper .image {
        max-height: 320px;
    }
    .grid-view .box--grid .box--column.fund .bx-viewport {
        max-height: 330px;
    }
    .grid-view .box--grid .box--column {
        min-height: 255px;
    }
    .grid-view .box--banner.box--banner-fourths {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .grid-view .box--banner.box--banner-three {
        grid-template-columns: 1fr 2fr 1fr;
    }
    .grid-view .box--grid .box--column .box--grid-wrapper .text {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
    }
    .grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction {
        position: absolute;
        bottom: 40px;
    }
    .grid-view .box--grid .box--column.fund-linked .pager-info {
        bottom: 78px;
    }
    .grid-view .box--grid .box--column.testimonial .pager-info {
        bottom: 50px;
    }
}

@media only screen and (min-width:1181px) and (max-width:1280px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 540px;
    }
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 530px;
    }
}

@media only screen and (min-width:769px) and (max-width:1180px) {
    .home .slider-wrap .bx-wrapper .bx-viewport,
    .product .slider-wrap .bx-wrapper .bx-viewport,
    .videofoto .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 390px;
    }
    .news .slider-wrap .bx-wrapper .bx-viewport {
        max-height: 380px !important;
    }
}

@media only screen and (min-width:0px) and (max-width:980px) {
    .grid-view .box--grid .box--column.double {
        grid-column: span 2/span 2;
        grid-row: 1;
    }
    .grid-view .box--grid .box--column.col-span-2 {
        grid-column: span 4/span 2;
    }
    .grid-view .box--grid .box--column .box--grid-wrapper .text {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .grid-view .box--grid .box--column.callus,
    .grid-view .box--grid .box--column.fund {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .grid-view .box--grid .pager-info {
        bottom: 25%;
        margin-left: 25px;
    }
    .grid-view .box--grid .text-money h5 {
        font-size: 22px;
    }
    .grid-view .box--column.news .text {
        bottom: 0;
    }
    .grid-view .box .social-embed h5,
    .grid-view .box--grid .social-embed h5 {
        font-size: 16px;
    }
    .grid-view .box .social-embed .embed-area,
    .grid-view .box--grid .social-embed .embed-area {
        height: 250px;
        overflow: hidden;
    }
    .grid-view .box--grid .testi .bx-viewport {
        max-height: 290px;
    }
    .grid-view .box--grid .testi .img-testi {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width:768px) {
    .grid-view .box.slider-img-text .text {
        padding-left: 50px;
        padding-right: 50px;
    }
    .grid-view .box--grid .box--column.col-span-s-2 {
        grid-column: span 2/span 2;
    }
    .inner-content ul.col-3.manfaat {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 1rem;
    }
}

@media only screen and (min-width:768px) and (max-width: 980px) {
    footer h5 {
        font-size: 14px;
    }
    .inner-content {
        padding-left: 75px;
        padding-right: 75px;
    }
    .content .content-wrapper {
        width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
    .grid-view .box--grid .box--column {
        grid-column: span 2/span 2;
    }
    .grid-view .box--grid .box--column.fund-linked .pager-info {
        bottom: 78px;
    }
    .grid-view .box.slider-wrap .text,
    .grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager {
        bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .floatingmenu {
        left: 0;
        width: 100%;
    }
    .grid-view .box--grid .box--column {
        grid-column: span 4;
    }
    .grid-view .box--grid .box--column.fund-linked .pager-info {
        bottom: 110px;
    }
    .grid-view .box.slider-wrap .text {
        bottom: 100px;
    }
}

@media only screen and (min-width: 767px) {
    footer .grid {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto 0px 0px 0px;
        grid-gap: 60px 10px;
        grid-auto-flow: column;
    }
    footer .grid .grid-item {
        grid-row: 2;
    }
}